export default function Footer() {
    return (
        <div className="container-fluid bg-primary text-white mt-5 py-5 px-sm-3 px-md-5">
            <div className="container text-center py-5">
                <div className="d-flex justify-content-center mb-4">
                    <a className="btn btn-light btn-social mr-2" href="https://www.facebook.com/SunitBajracharya"><i
                        className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-light btn-social mr-2" href="https://www.linkedin.com/in/sunit-bajracharya/"><i
                        className="fab fa-linkedin-in"></i></a>
                    <a className="btn btn-light btn-social" href="https://www.instagram.com/sunitcb/"><i
                        className="fab fa-instagram"></i></a>
                </div>

                <p className="m-0"><b>Sunit Chudamani Bajracharya</b></p>
            </div>
        </div>
    )
}