
import MainPage from './components/MainPage/MainPage';

function App() {
  console.log(process.env.REACT_APP_AWS_URL);
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
