export default function Qualification() {
    return (
        <div class="container-fluid py-5" id="qualification">
            <div class="container">
                <div class="position-relative d-flex align-items-center justify-content-center">
                    <h1 class="display-1 text-uppercase text-white" style={{ '-webkit-text-stroke': '1px #dee2e6' }}>Qualification
                    </h1>
                    <h1 class="position-absolute text-uppercase text-primary">Education & Expericence</h1>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <h3 class="mb-4">My Education</h3>
                        <div class="border-left border-primary pt-2 pl-4 ml-2">
                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Master In Computer Science ComPro</h5>
                                <p class="mb-2"><strong>Maharishi International University</strong> | <small>2022 -
                                    Current</small></p>
                                <p>Completed on-campus studies and currently taking distance education courses to complete a
                                    Master's Degree in Computer Science (Available for full-time, W-2 employment).</p>
                            </div>
                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Bachelor In Computer Engineering</h5>
                                <p class="mb-2"><strong>Pokhara University</strong> | <small>2010 - 2014</small></p>
                                <p>Completed the Bachelor In Computer Engineering and certified in Nepal's prestigious Nepal
                                    Engineering Council to take in different projects related to the technical fields
                                    especially software related.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h3 class="mb-4">My Expericence</h3>
                        <div class="border-left border-primary pt-2 pl-4 ml-2">
                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Associate Engineering Manager</h5>
                                <p class="mb-2"><strong>CEDAR GATE TECHNOLOGIES</strong> | <small>Jan 2022 - July
                                    2022</small></p>
                                <p>
                                    Assessed project requirements and developed project plans and milestones, mitigating
                                    project risks. Coordinated with teams in Nepal
                                    and US to eliminate project bottlenecks. Managed team onboarding and off-ramp; managed
                                    access for team members and ensured
                                    compliance with organizational policies and HIPAA law.
                                </p>

                            </div>
                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Senior Software Engineer / Team Lead</h5>
                                <p class="mb-2"><strong>CEDAR GATE TECHNOLOGIES</strong> | <small>2020 - 2021</small></p>
                                <p>Played key role as senior member on elite technical team. Assessed software requirements;
                                    guided team in planning and executing assigned
                                    projects. Oversaw deployment in collaboration with DevOps team. Developed and reviewed
                                    technical
                                    documentation.
                                </p>
                            </div>
                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Software Engineer</h5>
                                <p class="mb-2"><strong>Deerwalk Services Pvt. Ltd.</strong> | <small>2016 - 2020</small>
                                </p>
                                <p>
                                    Developed dynamic analytical reporting module;
                                    remodeled existing features to
                                    improve reporting. Designed and implemented intuitive user interfaces. Conducted
                                    research to identify web and API platforms and develop user-friendly UI /
                                    UX design for 2 key company products.
                                </p>
                            </div>
                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Associate Software Engineer</h5>
                                <p class="mb-2"><strong>Deerwalk Services Pvt. Ltd.</strong> | <small>2015 - 2016</small>
                                </p>
                                <p>
                                    Developed software and modules using Java, Groovy, Grails, C#, and ASP.NET MVC 5.
                                    Conducted research on RESTful API development for sourcing end-user-based mobile
                                    applications allowing users to view status, fill assessment and record healthcare
                                    data.Developed user interfaces and maintained data representation.
                                </p>
                            </div>

                            <div class="position-relative mb-4">
                                <i class="far fa-dot-circle text-primary position-absolute"
                                    style={{ top: '2px', left: '-32px' }}></i>
                                <h5 class="font-weight-bold mb-1">Tainee</h5>
                                <p class="mb-2"><strong>Channakya Softwares Pvt. Ltd.</strong> | <small>2014 - 2015</small>
                                </p>
                                <p>
                                    Hired to hone coding skills as junior member of elite technical team.Optimized existing
                                    code; conducted technical troubleshooting and resolved technical
                                    issues.Participated in Sprint development lifecycle.Migrated legacy databases and
                                    applications to Entity framework.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}