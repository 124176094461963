import axios from "axios";

export async function getData() {
    let responseData = await axios.get(`${process.env.REACT_APP_AWS_URL}/blogs`).then((response) => {
        console.log(response.data);
        return response.data;
    })
    return responseData;
}

export async function postData(postData) {
    let responseData = await axios.post(`${process.env.REACT_APP_AWS_URL}/contact`, postData).then((response) => {
        return response.data;
    })
    return responseData;
}

export async function getBlogHtml(blogTitle){
    let responseData = await axios.get(`${process.env.REACT_APP_AWS_URL}/blogs/blog-by-id?title=${blogTitle}`).then((response) => {
        console.log(response.data);
        return response.data;
    })
    return responseData;
}