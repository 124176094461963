export default function AboutMe() {
    return (
        <div class="container-fluid py-5" id="about">
            <div class="container">
                <div class="position-relative d-flex align-items-center justify-content-center">
                    <h1 class="display-1 text-uppercase text-white" style={{ '-webkit-text-stroke': '1px #dee2e6' }}>About</h1>
                    <h1 class="position-absolute text-uppercase text-primary">About Me</h1>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-5 pb-4 pb-lg-0">
                        <img class="img-fluid rounded w-100" src="img/about.jpg" alt="" />
                    </div>
                    <div class="col-lg-7">
                        <h3 class="mb-4">Java Full-Stack Developer</h3>
                        <p>
                            As an experienced Java developer, my expertise lies in Angular, AWS technologies and relational
                            databases. With a great passion for developing quality software solutions, my goal is to deliver
                            applications that not only meet, but exceed customer expectations, thereby driving business
                            success.
                        </p>
                        <p>
                            Java is one of my core areas of expertise and I also have working knowledge of frameworks such
                            as Spring and Hibernate.
                        </p>
                        <p>
                            Understanding front-end development principles ensures that the applications I build are
                            responsive, accessible, and perform optimally across different devices and browsers.
                        </p>
                        <p>
                            My skills also extend to AWS technologies that have become an integral part of modern
                            cloud-based applications. I am well versed in leveraging AWS services such as Lambda, EC2, S3
                            and RDS to build scalable, resilient and cost effective solutions.
                        </p>
                        <p>
                            Relational databases play an important role in most enterprise applications, and I bring a
                            strong foundation in database design and management to my Java development work. With extensive
                            experience working with relational databases such as MySQL and PostgreSQL, I am well-versed in
                            designing efficient database schemas and writing optimized SQL queries that ensure optimal
                            performance.
                        </p>
                        <p>
                            My experience with continuous integration and automated testing frameworks allows me to deliver
                            reliable and bug-free software solutions.
                        </p>
                        <div class="row mb-3">
                            <div class="col-sm-6 py-2">
                                <h6>Name: <span class="text-secondary">Sunit Bajracharya</span></h6>
                            </div>
                            <div class="col-sm-6 py-2">
                                <h6>Degree: <span class="text-secondary">Master</span></h6>
                            </div>
                            <div class="col-sm-6 py-2">
                                <h6>Experience: <span class="text-secondary">8 Years</span></h6>
                            </div>
                            <div class="col-sm-6 py-2">
                                <h6>Email: <span class="text-secondary">sunit.bajracharya@gmail.com</span></h6>
                            </div>
                            <div class="col-sm-6 py-2">
                                <h6>Address: <span class="text-secondary">Stephens City, Virginia</span></h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}