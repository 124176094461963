
import $ from 'jquery';
import React from 'react';

import { Waypoint } from "react-waypoint";

export default function Skills() {

    let handleWaypoint = () => {

        $('.progress .progress-bar').each(function () {
            $(this).css("width", $(this).attr("aria-valuenow") + '%');
        });
    }

    return (
        <div className="container-fluid py-5" id="skill">
            <div className="container">
                <div className="position-relative d-flex align-items-center justify-content-center">
                    <h1 className="display-1 text-uppercase text-white" style={{ '-webkit-text-stroke': '1px #dee2e6' }}>Skills</h1>
                    <h1 className="position-absolute text-uppercase text-primary">My Skills</h1>
                </div>
                <Waypoint onEnter={handleWaypoint}>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">JAVA Software Development</h6>
                                    <h6 className="font-weight-bold">95%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="95" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Grails Application Development</h6>
                                    <h6 className="font-weight-bold">85%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="85" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">C# / .Net Technologies</h6>
                                    <h6 className="font-weight-bold">90%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-danger" role="progressbar" aria-valuenow="90" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Microsoft SQL Server</h6>
                                    <h6 className="font-weight-bold">65%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info5" role="progressbar" aria-valuenow="65" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">HTML5 / CSS3 / Javascript / jQuery</h6>
                                    <h6 className="font-weight-bold">90%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info" role="progressbar" aria-valuenow="90" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Hibernate / MySQL</h6>
                                    <h6 className="font-weight-bold">90%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info4" role="progressbar" aria-valuenow="90" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Angular JS</h6>
                                    <h6 className="font-weight-bold">70%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-dark" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Web Services / API Development</h6>
                                    <h6 className="font-weight-bold">85%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info1" role="progressbar" aria-valuenow="85" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">iOS Developement with Swift 3</h6>
                                    <h6 className="font-weight-bold">50%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info2" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Amazon Web Services</h6>
                                    <h6 className="font-weight-bold">40%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info3" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Waypoint>
            </div>
        </div>
    )
}