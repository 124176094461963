import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { getData } from "../../services/AxiosService";

export default function Blog() {
    const navigate = useNavigate();
    const [listFiles, setListFiles] = useState([]);
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let getBlogData = async () => {
        console.log("GETTING FILES");
        getData().then((response) => {
            var blogFiles = []
            var data = response.Contents;
            console.log(data);
            data.filter(x => x.Key !== "blogs/" && x.Key.includes('.html')).forEach((item, index) => {
                var modifieldDate = new Date(item.LastModified);
                blogFiles.push({ title: item.Key.replace('blogs/', '').replace('/blog.html', ''), date: modifieldDate.getDate(), month: modifieldDate.getMonth() })
            });
            setListFiles(blogFiles);
        })
    }

    useEffect(() => {
        getBlogData()
    }, []);

    const handleBlogNav = (title) => {
        navigate(`/blogs?title=${title}`);
    }

    return (
        <div className="container">
            <div className="container-fluid pt-5" id="blog">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">

                        <h1 className="display-1 text-uppercase text-white" style={{ '-webkit-text-stroke': '1px #dee2e6' }}>Blogs</h1>
                        <h1 className="position-absolute text-uppercase text-primary">Latest Blog</h1>
                    </div>
                    <div className="row">
                        {listFiles &&
                            listFiles.map((item, index) => (
                                <div className="col-lg-4 mb-5">
                                    <div className="position-relative mb-4">
                                        <img className="img-fluid blog-img rounded w-100" src={"img/" + item.title + ".jpg"} alt="" />
                                        <div className="blog-date">
                                            <h4 className="font-weight-bold mb-n1">{item.date}</h4>
                                            <small className="text-white text-uppercase">{month[item.month]}</small>
                                        </div>
                                    </div>
                                    <h5 className="font-weight-medium mb-4">{item.title}</h5>
                                    <Link className="btn btn-sm btn-outline-primary py-2" to={{ pathname: '/blogs', search: `?title=${item.title}` }}>Read More</Link>
                                    {/* <a className="btn btn-sm btn-outline-primary py-2" href={"/blog/" + item.title + "/blog.html"}>Read More</a> */}
                                </div>

                            ))}
                    </div>
                </div>
            </div>
        </div >
    )
}