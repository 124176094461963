import { useState } from "react";
import { postData } from "../../services/AxiosService";

export default function Contact() {
    const [inputs, setInputs] = useState({});
    const [status, setStatus] = useState(false);
    const [hideButton, setHideButton] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        setHideButton(true);
        setStatus(true);

        postData(inputs).then((response) => {
            console.log("Reponse from server");
            console.log(JSON.stringify(response));
            setStatus({ type: 'success' });
            setHideButton(true);
        }, (error) => {
            setStatus({ type: 'error', error });

            console.log("ERROR");
            console.log(error);
        });
        event.preventDefault();
        console.log(JSON.stringify(inputs));
    }

    const SubmitButton = () => (
        <button className="btn btn-outline-primary" type="submit" id="sendMessageButton" disabled={hideButton ? "disabled" : false}>Send
            Message</button>
    );

    const SuccessInfo = () => (
        <div className='alert alert-success'>
            <button type='button' className='close' data-dismiss='alert' aria-hidden='true'>&times;</button>
            <strong>Your message has been sent. </strong>
        </div>
    )

    const FailInfo = () => (
        <div className='alert alert-danger'>
            <button type='button' className='close' data-dismiss='alert' aria-hidden='true'>&times;
            </button>
            <strong>Sorry, {inputs.GuestName} it seems your mail server is not responding. Please try again later!</strong>
        </div>
    )

    return (
        <div className="container-fluid py-5" id="contact">
            <div className="container">
                <div className="position-relative d-flex align-items-center justify-content-center">
                    <h1 className="display-1 text-uppercase text-white" style={{ '-webkit-text-stroke': '1px #dee2e6' }}>Contact</h1>
                    <h1 className="position-absolute text-uppercase text-primary">Contact Me</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="contact-form text-center">
                            <div id="success">

                                {status ? (hideButton ? <SuccessInfo /> : <FailInfo />) : null}
                            </div>
                            <form onSubmit={handleSubmit} >
                                <div className="form-row">
                                    <div className="control-group col-sm-6">
                                        <input type="text" className="form-control p-4" id="name" name="GuestName" placeholder="Your Name" value={inputs.name}
                                            onChange={handleChange}
                                            required="required" data-validation-required-message="Please enter your name" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group col-sm-6">
                                        <input type="email" className="form-control p-4" id="email" placeholder="Your Email" name="Email"
                                            required="required" data-validation-required-message="Please enter your email" value={inputs.email}
                                            onChange={handleChange} />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="control-group col-sm-8">
                                        <input type="text" className="form-control p-4" id="MessageTitle" placeholder="Subject" name="MessageTitle"
                                            required="required" data-validation-required-message="Please enter a subject" value={inputs.subject}
                                            onChange={handleChange} />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group col-sm-4">
                                        <input type="phone" className="form-control p-4" id="Phone" placeholder="Phone #" name="Phone" value={inputs.phone}
                                            onChange={handleChange} />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control py-3 px-4" rows="5" id="message" placeholder="Message" value={inputs.message}
                                        onChange={handleChange} name="Message"
                                        required="required"
                                        data-validation-required-message="Please enter your message"></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    {hideButton ? null : <SubmitButton />}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}