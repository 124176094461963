import { Route, Routes, useParams } from "react-router-dom";
import BlogsContainer from "../components/Blogs/BlogsContainer";
import MainPage from "../components/MainPage/MainPage";

export default function PageRouter() {
    const { blogTitle } = useParams();
    return (
        <Routes>
            <Route path="/" Component={MainPage}/>
            <Route path="/blogs" Component={BlogsContainer} />
        </Routes>
    )
}