import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getBlogHtml } from "../../services/AxiosService";

export default function BlogsContainer() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [htmlContent, setHtmlContent] = useState("");
    const blogTitle = searchParams.get('title');

    useEffect(() => {
        getBlogHtml(blogTitle).then((response) => {
            setHtmlContent(response)
        })
    }, [])

    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </React.Fragment>
    )
}