export default function Projects() {
    return (
        <div class="container-fluid pt-5" id="service">
            <div class="container">
                <div class="position-relative d-flex align-items-center justify-content-center">
                    <h1 class="display-1 text-uppercase text-white" style={{ '-webkit-text-stroke': '1px #dee2e6' }}>Service</h1>
                    <h1 class="position-absolute text-uppercase text-primary">My Projects</h1>
                </div>
                <div class="row pb-3">
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">Healthcare File Mapper & API</h4>
                        </div>
                        <p>Healthcare File Mapper & API is an custom ETL tool that provides intuitive UI that assists data
                            analysts to map client’s
                            raw data into a streamlined format accepted by Deerwalk/Cedargate data formats and all the
                            required configurations for the Deerwalk's ETL process and allows scrum-like reelase mechanism.
                        </p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">Plan Analytics</h4>
                        </div>
                        <p>Plan Analytics is a software solution that provides integrated informatics and actionable
                            insights and savings recommendations, presented in an easy-to-use application that requires no
                            special training. </p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">Manushi Exports</h4>
                        </div>
                        <p>Content management and transcation management web application for Manushi Exports</p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">MS Restro</h4>
                        </div>
                        <p>MS Restro is a web application that can help in restaurant business owner manage customer
                            billing, inventory & office accounting at single or multiple outlets operations remotely and
                            bring transparency amount business partners and easy access to outlet reports through web
                            software or mobile app.</p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">Incentives Manager</h4>
                        </div>
                        <p>One of the foundation software for healthcare incentives delegation from healthcare providers to
                            client groups and vendor groups.</p>

                    </div>

                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">Health Portal Web & API</h4>
                        </div>
                        <p>Health Portal Web provides simple yet sophisticated ways of integrating and organizing health
                            data. It packages the resulting individualized information, delivers it to members and supports
                            them in taking action to meet their personal health goals.</p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">DAS ODATA</h4>
                        </div>
                        <p>It is an OASIS standard that defines a set of best practices for building and consuming RESTful
                            APIs. As a part of exposing existing API to open standard based Web Api, where client can use
                            numerous Business Intelligence tools to create reports as required. Involved in documenting the
                            technical aspects of this application along with troubleshooting measures.</p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">WPM Meds</h4>
                        </div>
                        <p>Medical inventory management system for one of Nepal's online medical suppliers.</p>

                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-5">
                        <div class="d-flex align-items-center justify-content-center mb-4">

                            <h4 class="font-weight-bold m-0">Peak Healthcare</h4>
                        </div>
                        <p>The web application is used for coding, auditing and validating claims of members. It is also
                            used for scheduling HRA workflows for Nurse Practitioners and retrieving members' chart from
                            providers for further processing.</p>

                    </div>
                </div>
            </div>
        </div>
    )
}