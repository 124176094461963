import React from "react";

export default function MainPageNav() {
    return (
        <React.Fragment>
            <nav className="navbar fixed-top shadow-sm navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-lg-5">
                <a href="#" className="navbar-brand ml-lg-3">
                    <h1 className="m-0 display-5">
                        <span className="text-primary">
                            S
                            <span>
                                c
                            </span>
                        </span>
                        B
                    </h1>
                </a>

                <div className="collapse navbar-collapse px-lg-3" id="navbarCollapse">
                    <div className="navbar-nav m-auto py-0">
                        <a href="#home" className="nav-item nav-link active">Home</a>
                        <a href="#about" className="nav-item nav-link">About</a>
                        <a href="#qualification" className="nav-item nav-link">Qualification</a>
                        <a href="#skill" className="nav-item nav-link">Skill</a>
                        <a href="#service" className="nav-item nav-link">Projects</a>
                        <a href="#blog" className="nav-item nav-link">Blog</a>
                        <a href="#contact" className="nav-item nav-link">Contact</a>
                    </div>
                </div>
            </nav>

            <div className="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src="" id="video" allowscriptaccess="always"
                                    allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>


    )
}