import React, { useEffect } from "react";
import AboutMe from "./AboutMe";
import Blog from "./Blog";
import Contact from "./Contact";
import Header from "./Header";
import MainPageNav from "./MainPageNav";
import Projects from "./Projects";
import Qualification from "./Qualification";
import Skills from "./Skills";
import $ from 'jquery';
import 'jquery-ui/ui/effect';

export default function MainPage() {

    useEffect(() => {
        const handleNavClick = function (event) {
            console.log("ASASASASASASDFFFFFFFFFF");
            if (this.hash !== "") {
                event.preventDefault();

                $('html, body').animate(
                    {
                        scrollTop: $(this.hash).offset().top - 45,
                    },
                    1500,
                    'easeInOutExpo'
                );

                if ($(this).parents('.navbar-nav').length) {
                    $('.navbar-nav .active').removeClass('active');
                    $(this).closest('a').addClass('active');
                }
            }
        };

        $(".navbar-nav a").on('click', handleNavClick);

        return () => {
            $(".navbar-nav a").off('click', handleNavClick);
        };
    }, []);

    return (
        <React.Fragment>
            <MainPageNav />
            <Header />
            <AboutMe />
            <Qualification />
            <Skills />
            <Projects />
            <Blog />
            <Contact />
        </React.Fragment>
    );
}