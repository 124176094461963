import { useEffect, useRef } from "react"
import Typed from "typed.js"

export default function Header() {

    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ["JAVA Full-Stack Developer", "Software Engineer", "Angular Developer", "Photographer", "Travel Enthusiast", "Cloud Computing Student", "A guy with go-getter attitude", "Quick Learner", "Leading with an example"],
            typeSpeed: 70,
            loop: true
        });

        return () => {
            typed.destroy();
        }
    }, []);

    return (
        <div className="container-fluid bg-primary d-flex align-items-center mb-5 py-5" id="home">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 px-5 pl-lg-0 pb-5 pb-lg-0">
                        <img className="img-fluid w-100 rounded-circle shadow-sm" src="img/profile.jpg" alt="" />
                    </div>
                    <div className="col-lg-7 text-center text-lg-left">
                        <h3 className="text-white font-weight-normal mb-3">I'm</h3>
                        <h1 className="display-3 text-uppercase text-primary mb-2" style={{ '-webkit-text-stroke': '2px #ffffff' }}>
                            Sunit Bajracharya</h1>
                        <h1 class="typed-text-output d-inline font-weight-lighter text-white" ref={el}></h1>
                        {/* <div className="d-flex align-items-center justify-content-center justify-content-lg-start pt-5">
                                <a href="" className="btn btn-outline-light mr-5">Download CV</a>
                                <button type="button" className="btn-play" data-toggle="modal"
                                    data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal">
                                    <span></span>
                                </button>
                                <h5 className="font-weight-normal text-white m-0 ml-4 d-none d-sm-block">Play Video</h5>
                            </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}